import { Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WbIncandescentTwoToneIcon from '@mui/icons-material/WbIncandescentTwoTone';
import { SiRedhat } from "react-icons/si";
import { BiGlassesAlt } from "react-icons/bi";
import { RiSurgicalMaskFill } from "react-icons/ri";
import { VscCircleSlash } from "react-icons/vsc";
import { useDispatch } from 'react-redux';
import { changeStartMeasureState } from 'store/measureStatus/measureStatusSlice';
import { grey } from '@mui/material/colors';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const ModalWrapper = styled.div`
  font-family: Rubik, Segoe UI, sans-serif;
  outline: none;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25em;
  height: 22rem;
  padding: 16px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  gap: 10px;

  @media (max-width: 500px) {
    width: 85%;
  }
`

const TitleWrapper = styled.div`
  margin: 0;
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 600;
`

const BoxWrapper = styled.div`
  margin: 0;
  width: 100%;
  height: 80%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

const BoxIcon = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
`

const BoxIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const BoxIconLayer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  padding: 1%;
  border: 1px solid #e1e1e1;
  background: rgb(246 246 246);
  border-radius: 50px;
`

const BoxIconLayerText = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-style: italic;
`

const BoxDescription = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const ButtonWrapper = styled.div`
  margin: 0;
  width: 100%;
  height: 10%;
  text-align: center;
`

export default function PopupInstruction() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  useEffect(() => {
    handleOpen();
  }, [])

  const handleButtonClick = () => {
    handleClose();
    dispatch(changeStartMeasureState(true));
  }

  return (
    <Wrapper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 10,
        }}
      >
        <ModalWrapper>
          <TitleWrapper>
            <InfoOutlinedIcon style={{ height: "1.25rem" }} />
            Instructions
          </TitleWrapper>
          <BoxWrapper>
            <BoxIcon>
              <BoxIconWrapper>
                <BoxIconLayer>
                  <SiRedhat style={{ position: "absolute", width: "60%", height: "60%" }} />
                  <VscCircleSlash style={{ position: "absolute", width: "100%", height: "100%", color: "red" }} />
                </BoxIconLayer>
                <BoxIconLayerText>No Hat</BoxIconLayerText>
              </BoxIconWrapper>

              <BoxIconWrapper>
                <BoxIconLayer>
                  <BiGlassesAlt style={{ position: "absolute", width: "60%", height: "60%" }} />
                  <VscCircleSlash style={{ position: "absolute", width: "100%", height: "100%", color: "red" }} />
                </BoxIconLayer>
                <BoxIconLayerText>No Glasses</BoxIconLayerText>
              </BoxIconWrapper>

              <BoxIconWrapper>
                <BoxIconLayer>
                  <RiSurgicalMaskFill style={{ position: "absolute", width: "60%", height: "60%" }} />
                  <VscCircleSlash style={{ position: "absolute", width: "100%", height: "100%", color: "red" }} />
                </BoxIconLayer>
                <BoxIconLayerText>No Mask</BoxIconLayerText>
              </BoxIconWrapper>

              <BoxIconWrapper>
                <BoxIconLayer>
                  <WbIncandescentTwoToneIcon style={{ position: "absolute", width: "60%", height: "60%", color: "#EDED00" }} />
                </BoxIconLayer>
                <BoxIconLayerText>Well-lit</BoxIconLayerText>
              </BoxIconWrapper >

            </BoxIcon >
            <BoxDescription>
              Please remove your glasses, do not wear a mask or hat, stand in a well-lit area. Press the start button to read the vitals.
            </BoxDescription>
          </BoxWrapper >
          <ButtonWrapper>
            <Button variant="outlined"
              sx={{
                width: "100%",
                borderRadius: "10px",
                backgroundColor: '#f3f3f3',
                borderColor: '#999999',
                color: "black",
                fontWeight: 700,
                fontFamily: "Arial, Helvetica, sans-serif",
                '&:hover': {
                  backgroundColor: grey[300],
                  borderColor: '#999999',
                  boxShadow: 'none',
                },
                '&:active': {
                  boxShadow: 'none',
                  backgroundColor: grey[300],
                  borderColor: '#FFF',
                },
              }}
              onClick={handleButtonClick}
            >START VITALS SCAN
            </Button>
          </ButtonWrapper>

        </ModalWrapper >
      </Modal >
    </Wrapper >
  )
}
