import { Flex } from "components/shared/Flex";
import VitalSignCard from "components/VitalResultCard/VitalSignCard";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ProgressBar from "components/VitalResultCard/ProgressBar";
import BottomBar from "components/shared/BottomBar";
import IsabelResult from "../../components/IsabelResult/IsabelResult";
import parse from "html-react-parser";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { jsPDF } from "jspdf";
import * as htmlToImage from 'html-to-image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PhoneIcon from '@mui/icons-material/Phone';
import { useSearchParams } from 'react-router-dom';
import { env_server } from "../../data/env/envKey.env";
import { grey } from '@mui/material/colors';

import {
  ISABEL_DATA,
  PROFILE_DATA,
  STATUS_KEY,
  VITALSIGN_DATE,
  VITALSIGN_KEY,
  VitalElementArray,
  rangeCheckingVitalSign,
} from "data/constant/vitalConstraint";
import { Helper } from "utils/helper/helper.helper";
import { IsabelDescription } from "data/constant/isabelDescription";
import { GetIcon, GetVitalConstantData, Selection } from "data/constant/vitalDescription";
import { UseQueryResult, useMutation, useQuery } from "react-query";
import { ReturnResult } from "data/model/returnResult.model";
import { measureService } from "services/measureService.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Box from '@mui/material/Box';
import Loading from "components/Loading/Loading";
import { Button } from "@mui/material";
import { uploadService } from "services/uploadService.service";
import { disableUploadPDF } from "store/uploadFile/uploadFileSession";
import { changeLoadingProcessingMeasureDataState } from "store/measureStatus/measureStatusSlice";
import { useSessionTimer } from "hooks/useSessionTimer";

const ModalWrapper = styled.div`
  font-family: Rubik, Segoe UI, sans-serif;
  outline: none;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25em;
  height: 12rem;
  padding: 16px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  gap: 10px;

  @media (max-width: 500px) {
    width: 85%;
  }
`

const BoxDescription = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  flex-grow: 1;
`

const Wrapper = styled(Flex)`
  font-family: Rubik, Segoe UI, sans-serif;
  /* height: 88%; */
  height: 84%;
  width: 100%;
  overflow: auto;
  @media (max-height: 700px) {
    height: 82%;
  }
`;

const BoxWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  align-items: stretch;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 1rem;
  gap: 1rem;
  z-index: 0;

  @media (max-width: 700px) {
    height: fit-content;
    padding: 0;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

const TabsWrapper = styled(Flex)`
  font-family: Rubik, Segoe UI, sans-serif;
  position: relative;
  flex-direction: column;
  height: 99%;
  align-items: center;
  flex-grow: 1;
`;

const Bottom = styled(Flex)`
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-grow: 1;
  @media (max-width: 700px) {
    height: auto;
  }
`;

const OverrallStatusWrapper = styled(Flex)`
  border-radius: 2rem;
  flex-direction: column;
  align-items: start;
  justify-self: center;
  gap: 1rem;
  z-index: 16;
  @media (max-width: 700px) {
    flex-direction: row;
    gap: 2rem;
  }
  @media (max-width: 360px) {
    gap: 0;
  }
  @media (max-height: 400px) {
    gap: 0;
  }
`;

const CardWrapper = styled.div`
  height: 100%;
  width: 100%;
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  @media (max-width: 480px) {
    width: 100%;
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const TitleH1 = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  font-size: 2.2vh;
  font-weight: 700;
  align-self: center;
  justify-self: center;
  display: none;
  @media (max-width: 700px) {
    display: block;
    padding: 0.5rem 0;
  }
`;
const InformationText = styled(Flex)`
  font-size: 20px;
  font-weight: 600;
  color: #7f8e9b;
  @media (max-width: 1135px) {
    font-size: 18px;
  }
  @media (max-width: 1050px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 15px;
  }
  @media (max-width: 700px) {
    font-size: 20px;
  }
  @media (max-height: 400px) {
    font-size: 16px;
  }
  @media (max-width: 360px) {
    font-size: 16px;
  }
`;

const TitleH4 = styled(Flex)`
  font-size: 18px;
  font-weight: 500;
  @media (max-width: 950px) {
    font-size: 15px;
  }
  @media (max-width: 700px) {
    margin-top: 30px;
  }
  @media (max-width: 480px) {
    width: 95%;
    margin-top: 25px;
  }
  @media (max-width: 320px) {
    width: 90%;
    margin-top: 15px;
  }
  @media (max-width: 240px) {
    margin-top: 5px;
  }
`;

const Paragraph = styled(Flex)`
  font-size: 14px;
  align-self: start;
  align-items: center;
  justify-self: start;
  justify-content: flex-start;
  @media (max-width: 950px) {
    font-size: 12px;
  }
`;

const Recommendation = styled(Flex)`
  font-size: 15px;
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  font-style: italic;
  color: #7a8395;
  @media (max-width: 950px) {
    font-size: 1.3vh;
  }
  @media (max-width: 700px) {
    font-size: 11px;
  }
  @media (max-width: 480px) {
    width: 95%;
  }
  @media (max-width: 320px) {
    width: 90%;
  }
  @media (max-width: 240px) {
    font-size: 0.4rem;
  }
`;

const Time = styled(Flex)``;

const Day = styled(Flex)``;

const TimeDay = styled(Flex)`
  font-size: 0.85rem;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  height: 4%;
  color: #7a8395;
  gap: 5px;
  @media (max-width: 1135px) {
    font-size: 0.8rem;
  }
  @media (max-width: 1050px) {
    font-size: 0.7rem;
  }
  @media (max-width: 768px) {
    font-size: 0.65rem;
  }
  @media (max-width: 700px) {
    font-size: 0.8rem;
  }
  @media (max-width: 360px) {
    font-size: 0.68rem;
  }
`;
const ProgressBarWrapper = styled(Flex)`
  width: 100%;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 700px) {
    gap: 0;
  }
`;
const ProfileDetail = styled(Flex)`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  margin-bottom: 15px;
  align-self: flex-start;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.5rem;
  @media (min-width: 701px) {
    background-color: white;
    border: 1px solid #dfdfdf;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-radius: 10px;
    gap: 1rem;
  }
  @media (max-height: 400px) {
    gap: 0.5rem;
  }
`;
const IconImg = styled.img`
  height: 1.2rem;
  margin-left: -0.2rem;
  margin-right: 0.3rem;
  width: auto;
  position: relative;
  padding: 0.2rem;
  border-radius: 5px;
  background-color: #c1fae2;
  @media (max-width: 1135px) {
    height: 1rem;
  }
  @media (max-width: 1050px) {
    height: 0.9rem;
  }
  @media (max-width: 768px) {
    height: 0.8rem;
  }
  @media (max-width: 700px) {
    display: none;
  }
`;

const InformationWrapper = styled(Flex)`
  align-items: center;
  gap: 0.2rem;
`;

const PromptWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff3cd;
`;

const Prompt = styled(Flex)`
  text-align: center;
  flex-direction: column;
  padding: 0.5rem 1rem;
  font-size: 21px;
  background-color: #fff3cd;
  font-style: oblique;
  font-weight: 600;
  color: #a56404;
  @media (max-width: 1300px) {
    font-size: 20px;
  }
  @media (max-width: 1195px) {
    font-size: 19px;
  }
  @media (max-width: 1090px) {
    font-size: 18px;
  }
  @media (max-width: 1030px) {
    font-size: 16px;
  }
  @media (max-width: 1000px) {
    font-size: 15px;
  }
  @media (max-width: 700px) {
    font-size: 15px;
  }
  @media (max-width: 357px) {
    font-size: 14px;
  }
  @media (max-width: 335px) {
    font-size: 13px;
  }
  @media (max-width: 313px) {
    font-size: 12px;
  }
  @media (max-width: 292px) {
    font-size: 11px;
  }
  @media (max-width: 270px) {
    font-size: 10px;
  }
  @media (max-width: 249px) {
    font-size: 9.6px;
  }
`;
const Information = styled(Flex)`
  font-size: 16px;
  font-weight: 500;
  margin-right: 0.2rem;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 1135px) {
    font-size: 14px;
  }
  @media (max-width: 1050px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    font-size: 11px;
  }
  @media (max-width: 700px) {
    font-size: 16px;
  }
  @media (max-width: 360px) {
    font-size: 14px;
  }
`;
const InformationValue = styled(Flex)`
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 1135px) {
    font-size: 14px;
  }
  @media (max-width: 1050px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    font-size: 11px;
  }
  @media (max-width: 700px) {
    font-size: 16px;
  }
  @media (max-width: 360px) {
    font-size: 14px;
  }
`;

const IsabelWrapper = styled(Flex)`
  margin-bottom: 15px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 1rem 2rem;
  align-items: start;
  gap: 1rem;
  box-sizing: border-box;
  border: 1px solid #dfdfdf;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

  @media (max-width: 950px) {
    padding: 1rem;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    height: auto;
    width: 70%;
  }
  @media (max-width: 600px) {
    width: 80%;
  }
  @media (max-width: 480px) {
    width: 90%;
  }
`;

const IsabelBox = styled.div`
  height: 100%;
  margin-top: 5px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  gap: 1rem;
  border-radius: 10px;

  @media (max-width: 700px) {
    width: 95%;
  }
  @media (max-width: 240px) {
    margin: 0.156rem;
  }
`;

const SymptomsContent = styled.div`
  font-size: 0.9rem;
  color: #5e5e5e;
  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

const SymptomsContentStrong = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: #5e5e5e;
  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const IsabelResultBox = styled.div`
  /* height: 90%; */
  gap: 0.5rem;
  display: flex;
  flex-flow: column wrap;
  @media (max-width: 950px) {
    height: 80%;
  }
  @media (max-width: 700px) {
    /* gap: 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)); */
    display: flex;
    flex-wrap: wrap;
  }
`;

const ParagraphWrapper = styled(Flex)`
  width: 30%;
  min-width: 30%;
  flex-direction: column;
  gap: 1rem;
  color: #5e5e5e;
  margin-bottom: 5px;
  @media (max-width: 950px) {
    width: 40%;
  }
  @media (max-width: 480px) {
    width: 95%;
  }
  @media (max-width: 320px) {
    width: 90%;
  }
  @media (max-width: 700px) {
    border-top: 1px solid #dfdfdf;
    width: 100%;
  }
`;

const ContentWrapper = styled(Flex)`
  height: 100%;
  min-height: 100%;
  margin: 5px 0 0 0;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 360px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  @media (max-width: 700px) {
    height: auto;
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
  isResultIsabel?: boolean;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, isResultIsabel, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{
        width: "100%",
        height: isResultIsabel && window.innerWidth > 1024 ? "fit-content" : "100%",
        minHeight: "500px"
      }}
      {...other}
    >
      {value === index && (
        <div
          style={{
            height: "100%",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function Review() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState({ text: '', style: {} });
  const [result, setResult] = useState();
  const [resultMissing, setResultMissing] = useState([]);
  const [wellness, setWellness] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [datetime, setDatetime] = useState(null);
  const [isabelData, setIsabelData] = useState(null);
  const [isabelFlag, setIsabelFlag] = useState(false);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const loadingRef = useRef(null);
  const screenRef = useRef(null);
  const currentPage = useRef(null);
  const [indexPDF, setIndexPDF] = useState(0);
  const [arrayObjRef, setArrayObjRef] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const PDFname = useRef(null);
  const PDFRef = useRef(new jsPDF('p', 'mm', 'a4', true));
  const { isUploadPDF } = useSelector((state: RootState) => state.uploadFile);
  const { isExpire, session } = useSelector((state: RootState) => state.isExpire);
  const expireTime = useSessionTimer();
  const { filter, token } = useSelector((state: RootState) => state.paramData);
  const isReviewPageMeeting = window.location.pathname.indexOf('review') === -1 && env_server.PATIENT_ASSISSTANCE_MEETING_URL;
  const displayButtonMsh = searchParams.get('msh') === 'true' ? true : false;

  //#region Event catch the change of window W and H 
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  //#endregion

  //#region Change Tab event (Vital -> Isabel)
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  //#endregion

  //#region Extract Vital Data to UI
  const {
    data: patientData,
    status: patientDataStatus,
    isFetching: patientDataIsFetching,
    error: patientDataError,
  }: UseQueryResult<ReturnResult<string>, Error> = measureService.useGetKeyPairValue(token);

  useEffect(() => {
    const getProfileData = () => {
      var VitalsHistoryValue = JSON.parse(patientData.result);
      if (VitalsHistoryValue && VitalsHistoryValue.conditions && VitalsHistoryValue.conditions != "undefined") {
        const isabelJsonParseData = JSON.parse(decodeURIComponent(VitalsHistoryValue.conditions));
        if (isabelJsonParseData.length > 0) {
          try {
            setIsabelFlag(true);
            const isabel: {} = {};
            isabel[ISABEL_DATA.Condition] = JSON.parse(decodeURIComponent(VitalsHistoryValue.conditions));
            isabel[ISABEL_DATA.Symtoms] = VitalsHistoryValue.symptoms;
            setIsabelData(isabel);
          } catch (err) {
            console.log(err);
            setIsabelData(null);
          }
        } else {
          setIsabelFlag(false);
        }
      }

      if (VitalsHistoryValue) {
        const objConverted = Helper.ConvertJsonToLowercase(VitalsHistoryValue);
        var userDataObj = {};
        userDataObj[PROFILE_DATA.Gender] = objConverted[PROFILE_DATA.Gender];
        userDataObj[PROFILE_DATA.Age] = objConverted[PROFILE_DATA.Age];
        userDataObj[PROFILE_DATA.Pregnant] = objConverted[PROFILE_DATA.Pregnant];
        userDataObj[PROFILE_DATA.Symptoms] = objConverted[PROFILE_DATA.Symptoms];
        setProfileData(userDataObj);

        setDatetime(VitalsHistoryValue[VITALSIGN_DATE.MeasureDate]);

        // resolve data get the key and value
        var resultObj: any = {};
        var greenObj: any = {};
        var redObj: any = {};
        var unknownObj: any = {};
        var age: number;
        if (Object.keys(profileData).length != 0) {
          age = parseInt(userDataObj[PROFILE_DATA.Age]);
        } else {
          age = null;
        }
        Object.entries(VitalsHistoryValue).map((item) => {
          if (VitalElementArray.includes(item[0])) {
            if (item[0] !== VITALSIGN_KEY.WellnessRange) {
              if (rangeCheckingVitalSign(item[0], item[1]) === STATUS_KEY.NORMAL ||
                rangeCheckingVitalSign(item[0], item[1], age) === STATUS_KEY.MEDIUM) {
                greenObj[`${item[0]}`] = item[1];
              }
              else if (rangeCheckingVitalSign(item[0], item[1]) === STATUS_KEY.HIGH ||
                rangeCheckingVitalSign(item[0], item[1]) == STATUS_KEY.LOW
              ) {
                redObj[`${item[0]}`] = item[1];
              }
              else if (rangeCheckingVitalSign(item[0], item[1]) === STATUS_KEY.UNKNOWN) {
                unknownObj[`${item[0]}`] = item[1];
              }
            } else {
              var wellnessObj = [];
              wellnessObj.push(item[1]);
              wellnessObj.push(rangeCheckingVitalSign(item[0], item[1]));
              setWellness(wellnessObj);
            }
          }
        });
        resultObj = { ...redObj, ...greenObj, ...unknownObj };
        var checkMissingObj = { ...resultObj }
        checkMissingObj[`${VITALSIGN_KEY.WellnessRange}`] = wellness[0];
        handleCheckMissingData(checkMissingObj);
        setResult(resultObj);
      }
    }
    if (patientDataIsFetching == false) {
      getProfileData();
    }
  }, [patientDataIsFetching]);
  //#endregion

  //#region The "Missing Vital Sign" checker
  const isReviewPage = window.location.href.indexOf("vitalsign/review")
  const handleCheckMissingData = (resultObj: any) => {
    let dataMissing = [];
    for (var key in resultObj) {
      if (filter.length != 0) {
        if (resultObj[key] == "NaN" && filter.includes(key)) {
          let name = GetVitalConstantData(key, Selection.FullVitalName);
          dataMissing.push(name);
        }
      } else {
        if (resultObj[key] == "NaN") {
          let name = GetVitalConstantData(key, Selection.FullVitalName);
          //if the window isn't at the mobile screen
          if (screenSize.width > 700) {
            //If the missing data isn't BP, Hemo, A1c push the missing data into array 
            if ((key !== VITALSIGN_KEY.BloodPressure) && (key !== VITALSIGN_KEY.Hemoglobin) && (key !== VITALSIGN_KEY.HemoglobinA1c)) {
              dataMissing.push(name);
            }
          } else {
            dataMissing.push(name);
          }
        }
      }
    }
    setResultMissing(dataMissing);
  };
  //#endregion

  //#region Print to PDF logic
  const useUploadFileService = useMutation((variable: any) =>
    uploadService.uploadFile(variable.file, `${searchParams.get('token')?.toString()}_${Date.parse(JSON.parse(patientData.result).MeasureDate)}.pdf`)
    , {
      onSuccess: (data) => {
        // window.open(`${data.result}`, '_blank');
        setValue(Number.parseInt(currentPage.current));
        const arrayName = data.result.split("/");
        PDFname.current = `${arrayName[arrayName.length - 1]}`;
        dispatch(changeLoadingProcessingMeasureDataState(false));
        loadingRef.current.style.display = 'none';
        dispatch(disableUploadPDF());
      },
      onError: (error) => {
        console.log(error);
      }
    })
  const useDownloadFileService = useMutation(() =>
    uploadService.downloadFile(`${searchParams.get('token')?.toString()}_${Date.parse(JSON.parse(patientData.result).MeasureDate)}.pdf`), {
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

  useEffect(() => {
    if (isPrinting) {
      setTimeout(() => {
        htmlToImage.toCanvas(arrayObjRef[indexPDF].screen.current, { quality: 1 }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdfWidth = PDFRef.current.internal.pageSize.getWidth();
          const pdfHeight = PDFRef.current.internal.pageSize.getHeight();
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
          const imgX = (pdfWidth - imgWidth * ratio) / 2;
          const imgY = (pdfHeight - imgHeight * ratio) / 2;

          PDFRef.current.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
          if (PDFRef.current.getNumberOfPages() < arrayObjRef.length) {
            PDFRef.current.addPage();
            setIndexPDF(prev => ++prev);
            setValue(arrayObjRef[indexPDF + 1].index);
          } else {
            setIsPrinting(false);
            setIndexPDF(0);
            const file = PDFRef.current.output('blob');
            useUploadFileService.mutate({ file });
          }
        }).catch((err) => {
          console.log(err)
        })
      }, 5000)
    }
  }, [isPrinting, value, indexPDF])

  const autoSavePDF = () => {
    var aspectRatio: any;
    if (screenSize.width < 900) {
      aspectRatio = "p";
    } else {
      aspectRatio = "l";
    }

    interface IArrayObjRef {
      screen: any,
      index: number,
    }
    var tempArrayObjRef: IArrayObjRef[];

    if (isabelFlag) {
      tempArrayObjRef = [{ screen: screenRef, index: 0 }, { screen: screenRef, index: 1 }];
    } else {
      tempArrayObjRef = [{ screen: screenRef, index: 0 }];
    }

    setArrayObjRef(tempArrayObjRef);
    currentPage.current = value.toString();
    PDFRef.current = new jsPDF(aspectRatio, 'px', 'a4', true);
    loadingRef.current.style.display = 'block';
    setValue(0);
    setIsPrinting(true);
  }

  const downloadPDF = () => {
    PDFname.current ?
      PDFRef.current.save(`${PDFname.current}`) : useDownloadFileService.mutate();
  }

  const transferToBotVA = () => {
    window.location.href = `${env_server.PATIENT_ASSISSTANCE_MEETING_URL}/idle-v2?token=${searchParams.get('token')}`;
  }

  const handlePharmacy = () => {
    setIsOpenModal(true);
    setDescriptionModal({ text: 'Pharmacy', style: {} })
  }

  const handleDialEmergency = () => {
    setIsOpenModal(true);
    setDescriptionModal({ text: 'You have successfully dialed Emergency 112', style: { color: 'red' } })
  }

  useEffect(() => {
    if (isUploadPDF) {
      autoSavePDF();
    }
  }, [isUploadPDF])
  // #endregion

  return (
    <>
      <Modal open={isOpenModal} onClose={() => setIsOpenModal(false)} sx={{ fontFamily: "Rubik,Segoe UI,sans-serif" }}>
        <ModalWrapper>
          <BoxDescription style={descriptionModal?.style}>
            {descriptionModal?.text}
          </BoxDescription>
          <Button variant="outlined"
            sx={{
              width: "100%",
              borderRadius: "10px",
              backgroundColor: '#f3f3f3',
              borderColor: '#999999',
              color: "black",
              fontWeight: 700,
              fontFamily: "Arial, Helvetica, sans-serif",
              '&:hover': {
                backgroundColor: grey[300],
                borderColor: '#999999',
                boxShadow: 'none',
              },
              '&:active': {
                boxShadow: 'none',
                backgroundColor: grey[300],
                borderColor: '#FFF',
              },
            }}
            onClick={() => setIsOpenModal(false)}
          >Close</Button>
        </ModalWrapper >
      </Modal>

      <div style={{ height: "100%", width: "100%", position: "relative" }}>
        {
          !(isReviewPage > -1) && (session ? (
            <PromptWrapper>
              {!isExpire && resultMissing.length > 0 && (
                <Prompt>
                  <Box>Missing vital signs</Box>
                  <Box>
                    Free re-scan session remaining {Helper.FormatTimeFromMinutes(expireTime)}
                    {expireTime < 60 ? "s" : ""}
                  </Box>
                </Prompt>
              )}
              {!isExpire && resultMissing.length <= 0 && (
                <Prompt>
                  Free re-scan session remaining {Helper.FormatTimeFromMinutes(expireTime)}
                  {expireTime < 60 ? "s" : ""}
                </Prompt>
              )}
              {isExpire && <Prompt>Session end. Re-scan will charge a new session</Prompt>}
            </PromptWrapper>
          ) : (
            <PromptWrapper>
              <Prompt>Session end. Re-scan will charge a new session</Prompt>
            </PromptWrapper>
          ))
        }

        <Box width={"100%"} position={"relative"} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}
          sx={{
            "@media(max-width: 700px)": {
              flexDirection: displayButtonMsh && isReviewPageMeeting ? 'column' : 'row',
              right: '0',
              gap: '8px'
            },
            "@media(max-width: 1023px)": {
              flexDirection: displayButtonMsh && isReviewPageMeeting ? 'column' : 'row',
              right: '0',
              gap: '8px'
            }
          }}
        >
          <div style={{ position: "relative", display: "flex", justifyContent: "flex-end" }}>
            {displayButtonMsh && isReviewPageMeeting &&
              <Button variant="outlined" startIcon={<PhoneIcon sx={{
                "&": {
                  "@media(max-width: 700px)": {
                    fontSize: '12px !important',
                    right: '0'
                  }
                }
              }} />} onClick={handleDialEmergency} sx={{
                display: 'flex', textTransform: "capitalize", top: '4px', right: '4vw', zIndex: "15", background: "#FFF", width: '240px',
                "@media(max-width: 700px)": {
                  width: '180px',
                  fontSize: '12px',
                  right: '0'
                },
                "@media(max-width: 1023px)": {
                  position: 'initial',
                  marginTop: '12px'
                }
              }}>
                Dial Emergency 112
              </Button>}
            {
              displayButtonMsh && isReviewPageMeeting &&
              <Button variant="outlined" startIcon={<LocalHospitalIcon sx={{
                "&": {
                  "@media(max-width: 700px)": {
                    fontSize: '12px !important',
                  }
                }
              }} />} onClick={handlePharmacy} sx={{
                display: 'flex', textTransform: "capitalize", top: '4px', right: '3vw', zIndex: "15", background: "#FFF", width: '240px',
                "@media(max-width: 700px)": {
                  marginLeft: '12px',
                  width: '180px',
                  fontSize: '12px',
                  right: '0'
                },
                "@media(max-width: 1023px)": {
                  position: 'initial',
                  marginTop: '12px',
                  marginLeft: '12px'
                }
              }}>
                Pharmacy
              </Button>}
          </div>
          <div style={{ position: "relative", display: "flex", justifyContent: "flex-end" }}>
            {
              displayButtonMsh && isReviewPageMeeting &&
              <Button variant="outlined" startIcon={<VideoCameraFrontIcon sx={{
                "&": {
                  "@media(max-width: 700px)": {
                    fontSize: '12px !important',
                  }
                }
              }} />} onClick={transferToBotVA} sx={{
                display: 'flex', textTransform: "capitalize", top: '4px', right: '2vw', zIndex: "15", background: "#FFF", width: '240px',
                "@media(max-width: 700px)": {
                  width: '180px',
                  fontSize: '12px',
                  right: '0'
                },
                "@media(max-width: 1023px)": {
                  position: 'initial'
                }
              }}>
                Initiate online telemedicine
              </Button>}
            <Button variant="outlined" startIcon={<PictureAsPdfIcon sx={{
              "&": {
                "@media(max-width: 700px)": {
                  fontSize: '12px !important',
                }
              }
            }} />} onClick={downloadPDF} sx={{
              display: 'flex', textTransform: "capitalize", top: '4px', right: '1rem', zIndex: "15", background: "#FFF", width: '240px',
              "@media(max-width: 700px)": {
                marginLeft: '12px',
                width: displayButtonMsh && isReviewPageMeeting ? '180px' : '200px',
                fontSize: '12px',
                right: '0'
              },
              "@media(max-width: 1023px)": {
                position: 'initial',
                marginLeft: '12px'
              }
            }}>
              Download Med Records
            </Button >
          </div >
        </Box >

        <Box sx={{ zIndex: 1, position: "absolute", width: "100%", height: "100%", background: "white", display: "none" }} ref={loadingRef}>
          <Loading text="Please wait until the process is finished" />
        </Box>


        <Wrapper style={{ height: displayButtonMsh && isReviewPageMeeting ? screenSize.width < 1024 ? '70%' : '80%' : '88%' }}>
          <BoxWrapper ref={screenRef}>
            <TitleH1>Wellness Overall Status</TitleH1>
            <OverrallStatusWrapper>
              <ProgressBarWrapper>
                <ProgressBar
                  wellnessRange={Number.parseInt(wellness[0])}
                  wellnessRangeStatus={wellness[1]}
                />
                <TimeDay>
                  <Time>
                    {datetime
                      ? new Date(datetime.toString()).toLocaleTimeString()
                      : new Date().toLocaleTimeString()}
                  </Time>
                  <span> • </span>
                  <Day>
                    {datetime
                      ? new Date(datetime.toString()).toLocaleDateString()
                      : new Date().toLocaleDateString()}
                  </Day>
                </TimeDay>
              </ProgressBarWrapper>
              <ProfileDetail>
                <InformationText>Information</InformationText>
                {Object.keys(profileData).length !== 0 && (
                  <>
                    <InformationWrapper>
                      <IconImg src={GetIcon("Sex")}></IconImg>
                      <Information>Sex:</Information>
                      <InformationValue>{`${profileData[PROFILE_DATA.Gender]
                        ? Helper.DisplayGender(profileData[PROFILE_DATA.Gender])
                        : ""
                        }`}</InformationValue>
                    </InformationWrapper>
                    <InformationWrapper>
                      <IconImg src={GetIcon("Age")}></IconImg>
                      <Information>Age:</Information>
                      <InformationValue>{`${profileData[PROFILE_DATA.Age]
                        ? profileData[PROFILE_DATA.Age]
                        : ""
                        }`}</InformationValue>
                    </InformationWrapper>
                    <InformationWrapper>
                      <IconImg src={GetIcon("Pregnant")}></IconImg>
                      <Information>Pregnant:</Information>
                      <InformationValue>{`${profileData[PROFILE_DATA.Pregnant]
                        ? Helper.DisplayPregnant(
                          profileData[PROFILE_DATA.Pregnant]
                        )
                        : ""
                        }`}</InformationValue>
                    </InformationWrapper>
                  </>
                )}
              </ProfileDetail>
            </OverrallStatusWrapper>
            <TabsWrapper>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                TabIndicatorProps={{
                  style: {
                    background: "#00D681",
                    color: "#00D681 !important",
                    fontWeight: 600,
                  },
                }}
                sx={{
                  position: "relative",
                  width: "25rem",
                  "@media (max-width: 870px)": {
                    fontSize: "0.8rem",
                  },
                  "@media (max-width: 420px)": {
                    width: "100%",
                  },
                }}
                aria-label="review screen tab"
              >
                <Tab
                  label="VITAL SIGNS"
                  {...a11yProps(0)}
                  sx={{
                    fontWeight: 600,
                    fontSize: "0.8rem",
                    "&.Mui-selected": {
                      color: "#00D681",
                    },
                    "@media (max-width: 420px)": {
                      padding: 0,
                    },
                    "@media (max-width: 311px)": { fontSize: "0.65rem" },
                  }}
                />
                {isabelFlag && (
                  <Tab
                    label="Possible Conditions"
                    {...a11yProps(1)}
                    sx={{
                      fontWeight: 600,
                      fontSize: "0.8rem",
                      "&.Mui-selected": {
                        color: "#00D681",
                      },
                      "@media (max-width: 420px)": {
                        padding: 0,
                      },
                      "@media (max-width: 311px)": { fontSize: "0.65rem" },
                    }}
                  />
                )}
              </Tabs>

              <TabPanel value={value} index={0}>
                <ContentWrapper>
                  <Bottom>
                    <CardWrapper>
                      {result &&
                        Object.entries(result).map((item, index) => (
                          <VitalSignCard
                            key={index}
                            vitalSign={item[0]}
                            data={item[1]}
                          />
                        ))}
                    </CardWrapper>
                  </Bottom>
                </ContentWrapper>
              </TabPanel>

              {(isabelFlag && isabelData != null) && (
                <TabPanel value={value} index={1} isResultIsabel={true}>
                  <ContentWrapper>
                    <IsabelWrapper>
                      <IsabelBox>
                        <SymptomsContent>
                          <SymptomsContentStrong>Symptoms:</SymptomsContentStrong> {profileData[PROFILE_DATA.Symptoms]}
                        </SymptomsContent>
                        <IsabelResultBox>
                          {Object.entries(isabelData[ISABEL_DATA.Condition]).map((item, index) => (
                            <IsabelResult key={index} condition={item[1]} />
                          ))}
                        </IsabelResultBox>
                        <Recommendation>
                          {IsabelDescription.Recommendations}
                        </Recommendation>
                      </IsabelBox>
                      <ParagraphWrapper>
                        <TitleH4>{IsabelDescription.ResultDetailTitle}</TitleH4>
                        <Paragraph>
                          {parse(IsabelDescription.ResultDetail)}
                        </Paragraph>
                      </ParagraphWrapper>
                    </IsabelWrapper>
                  </ContentWrapper>
                </TabPanel>
              )}
            </TabsWrapper>
          </BoxWrapper>
        </Wrapper>
        <BottomBar />
      </div >
    </>
  );
}