import React, { useState } from "react";
import styled from "styled-components";
import { Flex } from "../shared/Flex";
import ErrorIcon from "../../assets/error-icon.svg";
import { ErrorCodeDetail } from "data/constant/vitalDescription";

const Wrapper = styled(Flex)`
  justify-content: space-around;
  padding: 5px;
  text-align: center;
  position: absolute;
  bottom: 5vh;
  height: fit-content;
  width: 90%;
  max-width: 700px;
  align-items: center;
  background-color: #fee7e7;
  border-radius: 20px;
  border: 0.35vh solid #ff0000;

  z-index: 150;
  animation:signup-response 2s 1;
  -webkit-animation:signup-response 2s 1;
  animation-fill-mode: forwards;

  animation-delay:12s;
  -webkit-animation-delay:12s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;

  @keyframes signup-response{
    from {opacity :1;}
    to {opacity :0;}
  }

  @-webkit-keyframes signup-response{
    from {opacity :1;}
    to {opacity :0;}
  }
`;

const Message = styled.div`
  font-size: 2vh;
  font-weight: 600;
  color: #c70808;
  text-align: center;
  @media (max-width: 700px){
    font-size: 1.75vh;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  height: 6vh;
`;

const ErrorCode = styled.div`
  color: #c70808;
  font-weight: 600;
  font-size: 2vh;
`;

const ErrorAlert = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <Wrapper>
      <IconContainer>
        <Icon src={ErrorIcon} />
        <ErrorCode>{`${message.split(" ").pop()}`}</ErrorCode>
      </IconContainer>
      <Message>{ErrorCodeDetail[`${message.split(" ").pop()}`]}</Message>
    </Wrapper>
  );
};
export default ErrorAlert;
